* {
  box-sizing: border-box;
}
.root {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  /* margin: 24px 27px; */
  align-items: center;
  background: #e5e5e5;
  width: 100%;
  height: 73px;
  padding: 0 29px;
  z-index: 10;
  top: 0;
}

.arrowBack {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #404246;
}
.arrowBack:active {
  opacity: 0.3;
}

.title {
  color: #25272e;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 37px;
  margin: 0 auto;
}

.beauty_button {
  display: initial;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  margin-left: 20px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgba(64, 66, 70, 0.2);
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
  text-align: center;
}

.beauty_button_confirm {
  display: initial;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgba(64, 66, 70, 0.2);
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
  margin-bottom: 15px;
}

.beauty_button_dop_serv {
  display: block;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgba(64, 66, 70, 0.2);
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
  margin: auto;
  margin-bottom: 15px;
  padding-top: 8px;
  
}

.root_beauty {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  /* margin: 24px 27px; */
  align-items: center;
  background: #e5e5e5;
  width: 100%;
  height: 73px;
  padding: 0 29px;
  z-index: 10;
  top: 0;
}
.title_beuaty {
  color: #25272e;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 37px;
  color: #404246;
  margin: 0 auto;
}

.celendar_beauty {
  margin-left: 20px;
}

.beauty_button:active,
.celendar_beauty:active {
  opacity: 0.6;
}

@media (max-width: 375px) {
  .title_beuaty,
  .beauty_button,
  .celendar_beauty {
    margin-left: 14px;
    text-align: center;
  }
}
@media (max-width: 320px) {
  .title_beuaty,
  .beauty_button,
  .bell_beuaty,
  .celendar_beauty {
    margin-left: 2px;
  }
  .root_beauty {
    padding: 0 5px;
  }
}

.modal {
  font-size: 12px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 20px;
  color: #a8a8a8;
  width: 355px;
  text-align: center;
}

.modal_child {
  font-size: 12px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 20px;
  color: #a8a8a8;
  width: 355px;
  text-align: center;
  margin: auto;
margin-top: 40px;
}

.modal_aut {
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 20px;
  color: #a8a8a8;
  width: 355px;
  text-align: center;
  height: 50px;
  margin-top: 20px;
  padding-top: 15px;
}

.header {
  width: 100%;
  /* border-bottom: 1px solid gray; */
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 5px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content {
  width: 100%;
  padding: 10px 5px;
  text-align: center;
}
.actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 15px;
  top: 10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #ffffff;
}

.input_label {
  display: flex;
  flex-direction: column;
  width: 314px;
  margin-left: 15px;
}

.input_label label {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 6px;
  color: #404246;
  opacity: 0.7;
}

.input_label input,
.input_label textarea {
  padding: 5px 10px;
  height: 40px;
  border: 1px solid#A8A8A8;
  border-radius: 5px;
  background: none;
  opacity: 0.7;
  margin-bottom: 20px;
}

.input_label label:focus,
.input_label input :focus {
  opacity: 1;
}

.dropdown_date {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  width: 144px;
  height: 40px;
  /* background: #ff7a00; */
  margin: auto;
  border: 0.5px solid #a8a8a8;
  border-radius: 5px;
  background: none;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  /* margin-left: 15px; */
}

.dropdown_time {
  border-radius: 5px;
  margin-left: 20px;
  width: 144px;
  height: 40px;
  color: #404246c7;
  padding-left: 20px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0.5px solid #a8a8a8;
  background: transparent
    url("data:image/svg+xml;utf8,<svg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z' fill='black'/></g></svg>")
    no-repeat;
  background-position: right 5px top 50%;
}

.dropdown_serv {
  border-radius: 5px;
  /* margin-left: 20px; */
  margin-bottom: 10px;

  width: 310px;  height: 35px;

  color: #404246c7;
  padding-left: 20px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0.5px solid #a8a8a8;
  background: transparent
    url("data:image/svg+xml;utf8,<svg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z' fill='black'/></g></svg>")
    no-repeat;
  background-position: right 5px top 50%;
}

.dop_section {
  display: block;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgba(64, 66, 70, 0.2);
  border-radius: 100%;
  border: 1px solid #e5e5e59e;
  margin: auto;
  margin-bottom: 10px;
  margin-top: -10px;
}
