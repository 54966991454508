.header {
  margin-top: 32px;
  margin-left: 24px;
  display: flex;
  margin-right: 24px;
}
.avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 50px;
  margin-right: 16px;
}
.avatar img {
  overflow: hidden;
  height: 74px;
  max-width: 75px;
  border-radius: 50px;
  background: linear-gradient(75deg, #ff8585 -0.07%, #c96a85 51.49%, #ff9e73 99.93%);
  border: 4px solid transparent;
}
.name_person {
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  word-wrap: break-word;
  color: #404246;
}

.proffesion_person {
  font-family: var(--GothamProRegular);

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */
  margin-top: 10px;
  color: #404246;
}

.info_subscribes {
  display: flex;
  margin-top: 26px;
  font-family: var(--GothamProRegular);
}

.info_subscribes div {
  display: flex;
  margin-right: 9px;
  font-size: small;
}

.icon_info_subscribes {
  margin-right: 6px;
}

.icon_info_balans {
  margin-right: 6px;
  margin-top: -5px;
}
.buttons_person {
  display: flex;
  margin: 0 23px;
  margin-top: 24px;
  margin-bottom: 16px;
  justify-content: space-between;
}

.button_subscribe,
.button_write,
.button_search {
  padding: 8px 16px;
  gap: 10px;
  font-family: var(--GothamProRegular);
  width: 138px;
  height: 32px;
  position: relative;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;
  margin-right: 5px;
  color: #404246;
}

.button_subscribe div,
.button_search div {
  position: absolute;
  background-color: var(--mainColor);
  height: 89%;
  top: 2px;
  width: 96.5%;
  left: 1.5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .button_subscribe:active,
.button_write:active,
.button_search:active {
  opacity: 0.6;
} */

.button_write:active {
  opacity: 0.6;
}

.button_write {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #f3f3f3;
  opacity: 1;
}

.button_search {
  width: 55px;
}
.button_search div {
  width: 93%;
}

.content {
  margin: 0 24px;
  padding-bottom: 91px;
}
.content_address {
  display: flex;
  margin-top: 17px;
  margin-bottom: 28px;
  align-items: center;
}
.content_address_geo {
  margin-right: 10px;
}

.content_address_text {
  margin-right: 11%;
  color: #404246;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.name_tabs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.name_tabs {
  border-bottom: 1px solid;
  padding-bottom: 11px;
}
.name_tabs div {
  position: relative;
  text-align: center;
}

.active_tab::before {
  position: absolute;
  content: '';
  width: 100%;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  border-radius: 5px;
  height: 5px;
  left: 0;
  top: 27px;
}

.tab {
  margin-top: 24px;
}

.tab_blog {
  display: flex;
  flex-wrap: wrap;
}
.tab_blog div img {
  width: 100%;
  height: 100%;
}
.tab_blog div {
  width: calc(100% / 3 - 1px);
  height: 30vw;
}

.buttonBeauty {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 132px;
  height: 32px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  margin-left: 20px;
  color: #f3f3f3;
  background: linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%);
  box-shadow: 1px 1px 4px rgb(64 66 70 / 20%);
  border-radius: 5px;
  border: 1px solid #e5e5e59e;
}

@media (max-width: 320px) {
  .header,
  .content {
    margin: 0 5px;

    margin-top: 15px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .button_subscribe,
  .button_write,
  .button_search {
    font-size: 12px;
  }
  .buttons_person {
    margin: 0 5px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

.container {
  margin-top: 40px;
}

.container .nav {
  position: relative;
}
.container .nav a {
  color: #404246;
}
.container .nav li {
  display: inline;
  color: #aaaaaa;
  font-size: 11px;
  font-family: 'proxima_nova_bold';
  text-transform: lowercase;
  /* letter-spacing: 3px; */
  margin-left: 10px;
  /* border-bottom: 1px solid #aaaaaa; */
  padding-bottom: 10px;
  margin-right: 10px;
  font-family: 'Gotham Pro Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
}
a {
  margin: 0;
  padding-bottom: 10px;
}
.nav li:hover a {
  border-bottom: 4px solid transparent;
  border-image: linear-gradient(
      to left,
      rgb(212, 50, 216) -0.07%,
      rgb(255, 96, 141) 51.49%,
      rgb(244, 165, 130) 99.93%
    )
    1 / 1 / 0 stretch;
  color: #404246;
  font-weight: 800;
}

.ftime {
  border-bottom: 4px solid transparent;
  border-image: linear-gradient(
      to left,
      rgb(212, 50, 216) -0.07%,
      rgb(255, 96, 141) 51.49%,
      rgb(244, 165, 130) 99.93%
    )
    1 / 1 / 0 stretch;
  color: #404246;
  font-weight: 800 !important;
}

.uv {
  /* display: inline-flex; */
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #aaaaaa;
}

.myRequests {
  text-align: center;
  margin-top: 40px;
}
