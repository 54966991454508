.root {
  margin: 0px 15px;
  position: relative;
  margin-bottom: 78px;
  background: #f3f3f3;
}

.service_blok {
/* border: 0.5px solid #404246; */
width: 68px;
height: 68px;
margin: 20px;
margin-top: 30px;
display: inline-block;
}

.service_blok img {
width: 68px;
height: 68px;
}

.services {
  /* border: 0.5px solid #404246; */
  /* width: 340px; */
  height: auto;
  margin: auto;
  text-align: center;
}

.services_wrapper {
  width: 100%;
}

.dropdown_filters {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  height: 20px;
  background: #404246;
  border-radius: 0px 0px 20px 20px;
  margin: auto;
}

.dropdown_filters:active {
  opacity: 0.3;
}

@media (max-width: 423px) {
  /* .all_items {
    margin-left: 10px;
  } */
  .dropdown_filters {
    margin: auto;
  }
}

.button_filter_svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: 40px;
  border: 0.5px solid #404246;
  border-radius: 5px;
}

.button_filter_svg:active {
  opacity: 0.3;
}















































.carousel {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 40px;
  display: inline-block;
  text-align: center;
}
.carousel img {
  width: 100%;
  margin: 0 auto;
  height: 368px;
}

.name_item_price {
  display: flex;
  justify-content: space-between;
}

.name_item,
.price_item {
  color: #404246;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
}

.price_item {
  margin-top: 15px;
  font-weight: 700;
  color: #ff7a00;
}

.sizes_item {
  margin-top: 25px;
}
.sizes {
  margin-bottom: 20px;
}

.title_sizes {
  margin-bottom: 16px;
  font-family: var(--GothamProRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #404246;
}

.one_size {
  display: inline-block;
  background: #f3f3f3;
  border: 0.5px solid #404246;
  border-radius: 5px;
  width: 56px;
  background: none;
  font-family: var(--GothamProRegular);

  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #404246;
  padding-top: 10px;
  text-align: center;
  margin-right: 14px;
  margin-bottom: 14px;
  padding-bottom: 10px;
  overflow: hidden;
}

.one_size_click {
  display: inline-block;
  background: #f3f3f3;
  border: 0.5px solid #ff7a00;
  border-radius: 5px;
  width: 56px;
  background: none;
  font-family: var(--GothamProRegular);
  color: #404246;

  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  padding-top: 10px;
  text-align: center;
  margin-right: 14px;
  margin-bottom: 14px;
  background: #ff7a00;
  padding-bottom: 10px;
  overflow: hidden;
}

.description {
  margin-top: 18px;
  padding-bottom: 89px;
}

.description_title,
.description_text {
  margin-bottom: 16px;
  font-family: var(--GothamProRegular);

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;

  color: #404246;
}

.buy {
  position: fixed;
  width: 138px;
  bottom: 72px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.shop_name_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shopname {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  position: absolute;
  text-align: center;
  color: #404246;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
